import React from 'react';
import { string, arrayOf, shape, bool } from 'prop-types';
import { Field as BaseField, FastField } from 'formik';
import Form from '@bit/medicalwebexperts.mwe-ui.form';
import Box from '@bit/medicalwebexperts.mwe-ui.box';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import MaskedField from './MaskedField';
import Select from './Select';

const propTypes = {
  mask: string,
  label: string,
  notFast: bool,
  name: string.isRequired,
  type: string.isRequired,
  required: bool,
  radios: arrayOf(shape({})),
  options: arrayOf(shape({})),
  checboxs: arrayOf(shape({})),
};

const defaultProps = {
  mask: undefined,
  label: undefined,
  notFast: false,
  options: [],
  radios: [],
  checboxs: [],
  required: false,
};

const classes = { root: { color: 'gray.600', fontWeight: 'medium' } };
const checkGroupClasses = { root: { '> label': { mb: 4 } } };

const Field = ({
  label,
  name,
  options,
  checboxs,
  radios,
  type,
  notFast,
  mask,
  required,
  ...props
}) => {
  const FormikField = notFast || type === 'password' ? BaseField : FastField;

  const errorBackgroundColor = '#dc354533';
  return (
    <FormikField name={name}>
      {({ field, form, meta }) => {
        const feedback = meta.error && meta.touched ? meta.error : undefined;
        const isValid = !(meta.error && meta.touched);

        const handleSelectChange = async (o) => {
          await form.setFieldValue(name, o);
          form.setFieldTouched(name);
        };
        const handleCheckChange = async (e) => {
          e.persist();
          await form.setFieldValue(
            name,
            field.value.includes(e.target.value)
              ? field.value.filter((i) => i !== e.target.value)
              : [...field.value, e.target.value],
          );
        };

        const styledLabel = required ? (
          <Box>
            {label}{' '}
            <Typography fontSize="sm" display="inline" color="danger">
              *
            </Typography>
          </Box>
        ) : (
          label
        );

        switch (type) {
          case 'select':
            return (
              <Select
                {...field}
                label={styledLabel}
                options={options}
                feedback={feedback}
                labelProps={{ classes }}
                onChange={handleSelectChange}
                // eslint-disable-next-line react/prop-types
                isDisabled={props.disabled}
                {...props}
              />
            );
          case 'password':
            return (
              <Form.PasswordInput
                labelProps={{ classes }}
                feedback={feedback}
                isValid={isValid}
                label={label}
                {...field}
                {...props}
              />
            );
          case 'radioGroup':
            return (
              <Form.CheckGroup
                labelProps={{ classes }}
                groupProps={{ classes: checkGroupClasses }}
                feedback={feedback}
                isValid={isValid}
                label={styledLabel}
                {...field}
                {...props}
              >
                {radios.map(({ value, ...rest }) => (
                  <Form.Radio key={value} value={`${value}`} {...rest} />
                ))}
              </Form.CheckGroup>
            );
          case 'checkGroup':
            return (
              <Form.CheckGroup
                {...field}
                label={label}
                isValid={isValid}
                feedback={feedback}
                labelProps={{ classes }}
                onChange={handleCheckChange}
                groupProps={{ classes: checkGroupClasses }}
                {...props}
              >
                {checboxs.map(({ value, ...rest }) => (
                  <Form.Check key={value} value={`${value}`} {...rest} />
                ))}
              </Form.CheckGroup>
            );
          case 'maskInput':
            return (
              <MaskedField
                labelProps={{ classes }}
                feedback={feedback}
                isValid={isValid}
                label={styledLabel}
                mask={mask}
                {...field}
                {...props}
              />
            );
          default:
            return (
              <Form.Field
                borderRadius="0"
                labelProps={{ classes }}
                feedback={feedback}
                isValid={isValid}
                label={styledLabel}
                type={type}
                backgroundColor={isValid ? 'white' : errorBackgroundColor}
                {...field}
                {...props}
              />
            );
        }
      }}
    </FormikField>
  );
};

Field.propTypes = propTypes;
Field.defaultProps = defaultProps;

export default Field;

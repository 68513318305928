import css from '@styled-system/css';
import styled from '@emotion/styled';

const ReCaptchaContainer = styled.div(
  css({
    alignSelf: 'center',
  }),
);

export default ReCaptchaContainer;

import React from 'react';
import { func, oneOfType, node, shape } from 'prop-types';
import { Formik as BaseFormik, Form as FormikForm } from 'formik';
import Form from '@bit/medicalwebexperts.mwe-ui.form';

const propTypes = {
  children: oneOfType([func, node]).isRequired,
  formProps: shape({}),
};

const defaultProps = {
  formProps: {},
};

const Formik = ({ children, formProps, ...props }) => (
  <BaseFormik {...props}>
    {(formikProps) => (
      <Form {...formProps} as={FormikForm}>
        {typeof children === 'function' ? children(formikProps) : children}
      </Form>
    )}
  </BaseFormik>
);

Formik.propTypes = propTypes;
Formik.defaultProps = defaultProps;

export default Formik;

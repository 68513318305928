import React from 'react';
import { bool, func, string } from 'prop-types';
import InputMask from 'react-input-mask';
import Form from '@bit/medicalwebexperts.mwe-ui.form';
import { Field } from 'formik';

import styled from '@emotion/styled';
import css from '@styled-system/css';

const propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  mask: string.isRequired,
  placeholder: string.isRequired,
  isValid: bool,
  getHandleChange: func,
};

const defaultProps = {
  isValid: null,
  getHandleChange: null,
};

const StyledInputMask = styled(InputMask)(({ plain, isValid, disabled }) =>
  css({
    '&::placeholder': {
      fontStyle: 'italic',
      color: 'text',
      opacity: 0.6,
    },
    backgroundColor: isValid ? 'white' : '#dc354533',
    border: '1px',
    bg: disabled && 'gray.200',
    borderColor: () => {
      if (!isValid) return 'danger';
      return plain ? 'transparent' : 'gray.400';
    },
    height: 10,
    marginBottom: 4,
    boxSizing: 'border-box',
    color: 'text',
    display: 'inline-block',
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'md',
    lineHeight: 'base',
    m: 0,
    p: 4,
    verticalAlign: 'middle',
    width: '100%',
  }),
);

const MaskedField = ({
  name,
  label,
  mask,
  placeholder,
  getHandleChange,
  ...props
}) => {
  return (
    <Field name={name}>
      {({ field, meta }) => {
        const feedback = meta.error && meta.touched ? meta.error : undefined;

        return (
          <Form.Group justifyContent="center">
            {label && (
              <Form.Label color="gray.600" fontWeight="medium">
                {label}
              </Form.Label>
            )}
            <StyledInputMask
              mask={mask}
              placeholder={placeholder}
              maskPlaceholder={null}
              {...field}
              {...props}
            />
            {feedback && (
              <Form.Feedback type="invalid">{feedback}</Form.Feedback>
            )}
          </Form.Group>
        );
      }}
    </Field>
  );
};

MaskedField.propTypes = propTypes;
MaskedField.defaultProps = defaultProps;

export default MaskedField;

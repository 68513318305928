import css from '@styled-system/css';
import styled from '@emotion/styled';

const AlertWrapper = styled.div(({ success }) =>
  css({
    padding: '20px',
    borderRadius: '6px',
    color: 'white',
    backgroundColor: success ? 'success' : 'danger',
  }),
);

export default AlertWrapper;

/* eslint-disable react/prop-types */
import React from 'react';
import Form from '@bit/medicalwebexperts.mwe-ui.form';
import { useTheme } from '@emotion/react';

const Select = ({
  label,
  feedback,
  labelProps,
  groupProps,
  size,
  ...props
}) => {
  const theme = useTheme();
  const { danger } = theme.colors;
  const gray = theme.colors.gray[400];

  const height = {
    sm: theme.sizes[8],
    md: theme.sizes[10],
    lg: theme.sizes[12],
  };

  const minHeight = size ? height[size] : height.md;

  const customStylesError = {
    control: (styles, state) => ({
      ...styles,
      border: state.isFocused ? `1px solid ${gray}` : `1px solid ${danger}`,
      backgroundColor: state.isValid || state.isFocused ? 'white' : '#dc354533',
      borderRadius: 0,
      minHeight,
    }),
  };

  const customStyles = {
    control: (styles) => ({
      ...styles,
      border: `1px solid ${gray}`,
      borderRadius: 0,
      minHeight,
    }),
  };

  return (
    <Form.Group {...groupProps}>
      {label && <Form.Label {...labelProps}>{label}</Form.Label>}
      <Form.Select
        styles={feedback ? customStylesError : customStyles}
        {...props}
      />
      {feedback && <Form.Feedback type="invalid">{feedback}</Form.Feedback>}
    </Form.Group>
  );
};

export default Select;

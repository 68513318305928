import css from '@styled-system/css';
import styled from '@emotion/styled';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';

const FormWrapper = styled(Flex)(
  css({
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    flexDirection: ['column', null, 'row'],
    width: '100%',
    '> *': {
      width: ['100%', null, '50%'],
      padding: '10px',
    },
  }),
);

export default FormWrapper;

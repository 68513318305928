import React from 'react';
import { useFormikContext } from 'formik';
import Button from '@bit/medicalwebexperts.mwe-ui.button';

const Submit = () => {
  const { isSubmitting } = useFormikContext();

  return (
    <Button
      display="block"
      type="submit"
      variantColor="primary"
      loading={isSubmitting}
      disabled={isSubmitting}
      block
      borderRadius={0}
    >
      Submit Form
    </Button>
  );
};

export default Submit;

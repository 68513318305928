import css from '@styled-system/css';
import styled from '@emotion/styled';

const Main = styled.main(
  css({
    padding: ['20px', null, '40px'],
    minHeight: '300px',
  }),
);

export default Main;
